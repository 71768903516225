import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { OmsFeatureShellModule } from '@surface-elements/oms/feature-shell';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    OmsFeatureShellModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
